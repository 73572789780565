nav{
    align-items: center;
    background-color: #292940;
    box-shadow: 0px 2px 7px black;
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 0 20px;
}

nav .nav-brand{
    color: white;
    font-size: 2em;
}

.toolbox{
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.toolbox label{
    color: white;
    display: block;
    text-align: center;
}

.toolbox > div{
    display: flex;
    justify-content: space-between;
}

.toolbox button{
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(64, 161, 240, 0.5);
   
    color: #f1f1f1;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    margin: 0 10px;
    outline: none;
    padding: 13px 10px;
}

.toolbox button:hover {
    border:2px solid #34B3F1;

}

.toolbox select, input[type='range']{
    cursor: pointer;
    margin: 0 10px;
}

.toolbox input, select, button{
    min-width: 130px;
}

.toolbox select {
    background: transparent;
    border-radius: 5px;

    border: 1px solid rgba(64, 161, 240, 0.5);
    color: white;
    font-size: 0.9em;
    font-weight: bold;
    padding: 5px 5px;
}

.toolbox select:hover {
    border:2px solid  #34B3F1;
}

@media(max-width: 992px){
    nav{
        flex-direction: column;
    }

    .toolbox{
        display: block;
        text-align: center;
    }

    .toolbox > div{
        margin: 10px 0;
    }

    .speed{
        display: none;
    }
}