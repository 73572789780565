.listGroup{
    margin: 0 auto;
    min-height: 520px;
    min-width: 90%;
    text-align: center;
}

.listGroup > .block{
    background-color: turquoise;
    color: #282c34;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 0 2px;
    text-align: center;
    transition-duration: 150ms;
    transition-property: height;
    transition-timing-function: ease-in;
}